


































import { Component, Vue } from 'vue-property-decorator';
import { BulkOpsType, BulkOpsTypeList } from '@/constants';
import { BulkOpsModel, BulkOpsDocument } from '@/models';

@Component({
  name: 'BulkOpsList',
})
export default class extends Vue {
  private isSearching: boolean = false;
  private results: BulkOpsDocument[] = [];
  private items: any[] = [];
  private expandRowKeys: string[] = [];
  private resultSliceSize: number = 10;
  private totalResults: number = 0;

  private mounted() {
    this.doSearch();
  }

  private getBulkOpsType(type: BulkOpsType): string {
    return BulkOpsTypeList[type];
  }

  private async doSearch(): Promise<void> {
    if (this.isSearching) {
      return;
    }

    this.isSearching = true;

    this.results = await BulkOpsModel.findActive();

    this.results.sort((d1, d2) => {
      const date1 = (d1.getCreatedDate(true) || new Date()) as any;
      const date2 = (d2.getCreatedDate(true) || new Date()) as any;
      return date2 - date1;
    });

    this.totalResults = this.results.length;
    this.setPagination(1);

    this.isSearching = false;
  }

  public setPagination(newPage: number): void {
    const startPos: number = Math.max(0, this.resultSliceSize * (newPage - 1));

    const resultSlice = this.results.slice(startPos, startPos + this.resultSliceSize);
    this.items = resultSlice.map(bulkOps => this.transformRecord(bulkOps));
    if (this.items.length > 0) {
      this.expandRowKeys = [this.items[0].id];
    }
  }

  private transformRecord(bulkOps: BulkOpsDocument): any {
    const id = bulkOps.id;
    const type = this.getBulkOpsType(bulkOps.type);
    const date = bulkOps.getCreatedDate();
    const success = bulkOps.success.map(msg => {
      const REG_EXP_SUCCESS = new RegExp('^Imported file \\"(\\S+)\\" to exercise \\"(.+)\\" \\((.+)\\)$', 'gm');
      const group = REG_EXP_SUCCESS.exec(msg);
      if (group) {
        const [, asset, exercise, brand] = group;
        return { msg, asset, exercise, brand };
      }
      return { msg };
    });
    const error = bulkOps.error.map(msg => {
      const REG_EXP_ERROR = new RegExp('^\\"(\\S+)\\" - (.+)$', 'gm');
      const group = REG_EXP_ERROR.exec(msg);
      if (group) {
        const [, asset, errorMsg] = group;
        return { msg, asset, errorMsg };
      }
      return { msg };
    });
    return { id, type, date, success, error };
  }

  private rowKey(item: any) {
    return item.id;
  }
}
