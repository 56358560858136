







































import { Component, Vue } from 'vue-property-decorator';
import BrandStore from '@/store/modules/brand';
import { Helper } from '@/util';
import { ProductType, ProductTypeList, MovementType, MovementTypeList } from '@/constants';
import { BrandDocument, ExerciseDocument, ExerciseModel, WorkoutModel, WorkoutDocument } from '@/models';

interface IResult {
  exercise: ExerciseDocument;
  issues: string[];
}

@Component({
  name: 'IntegrityReport',
})
export default class extends Vue {
  private brandMap: Record<string, BrandDocument> = (undefined as unknown) as Record<string, BrandDocument>;
  private productTypeMap: Record<ProductType, string> = (undefined as unknown) as Record<string, ProductType>;
  private movementTypeMap: Record<MovementType, string> = (undefined as unknown) as Record<string, MovementType>;
  private isSearching: boolean = false;
  private results: IResult[] = [];
  private resultSlice: IResult[] = [];
  private resultSliceSize: number = 20;
  private totalResults: number = 0;

  public created() {
    this.brandMap = BrandStore.list.reduce((accumulator, brand) => ({ ...accumulator, [brand.id]: brand }), {} as Record<string, BrandDocument>);
    this.productTypeMap = ProductTypeList;
    this.movementTypeMap = MovementTypeList;
    this.doSearch();
  }

  public gotoExercise(exerciseId: string): void {
    window.open(`/athletics/exercises/exercise/${exerciseId}`, '_blank');
  }

  private getNameList(list: Record<string, any>, selectedIDList: string[], prop?: string): string {
    const entries: string[] = Object.entries(list).reduce((accumulator, [key, val]) => {
      if (selectedIDList.includes(key)) {
        accumulator.push((prop ? val[prop] : val) as string);
      }

      return accumulator;
    }, [] as string[]);

    if (entries.length <= 1) {
      return entries.join();
    } else {
      return `${entries.slice(0, -1).join(', ')} and ${entries[entries.length - 1]}`;
    }
  }

  private async doSearch(): Promise<void> {
    if (this.isSearching) {
      return;
    }

    this.isSearching = true;

    this.results = await this.getExerciseResults();
    this.totalResults = this.results.length;
    this.setPagination(1);

    this.isSearching = false;
  }

  private async getExerciseResults(): Promise<IResult[]> {
    const [exercises, workouts] = await Promise.all([ExerciseModel.find({}), WorkoutModel.find({})]);
    const exerciseWorkoutMap: Record<string, WorkoutDocument[]> = workouts.reduce((accumulator, workout) => {
      workout.searchExerciseList.forEach(x => {
        if (!accumulator[x]) {
          accumulator[x] = [];
        }

        accumulator[x].push(workout);
      });

      return accumulator;
    }, {} as Record<string, WorkoutDocument[]>);

    return exercises
      .reduce((accumulator, exercise) => {
        if (!exerciseWorkoutMap[exercise.id]) {
          return accumulator;
        }

        const issues: string[] = [];

        exerciseWorkoutMap[exercise.id].forEach(workout => {
          if (!exercise.searchBrandList.includes(workout.workoutBrandId)) {
            issues.push(`Used in workout "${this.brandMap[workout.workoutBrandId].name} #${workout.sequence}" but not linked to brand`);
          } else {
            workout.productTypeWorkouts.forEach(productTypeSetup =>
              productTypeSetup.exerciseSetup.forEach(exerciseSetup => {
                if (exerciseSetup.exerciseId !== exercise.id) {
                  return;
                }

                if (!exercise.productTypes.includes(productTypeSetup.productType)) {
                  issues.push(
                    `Used in workout "${this.brandMap[workout.workoutBrandId].name} #${workout.sequence}" under "${
                      ProductTypeList[productTypeSetup.productType]
                    }" but not linked to that rig/kit`,
                  );
                } else if (!exercise.movementTypes.includes(exerciseSetup.movementType)) {
                  issues.push(
                    `Used in workout "${this.brandMap[workout.workoutBrandId].name} #${workout.sequence}" under "${
                      MovementTypeList[exerciseSetup.movementType]
                    }" but not linked to that movement type`,
                  );
                }
              }),
            );
          }
        });

        if (issues.length === 0) {
          return accumulator;
        }

        return [...accumulator, { exercise, issues }];
      }, [] as IResult[])
      .sort((a: any, b: any) => a.exercise.name.toLowerCase().localeCompare(b.exercise.name.toLowerCase()));
  }

  public setPagination(newPage: number): void {
    const startPos: number = Math.max(0, this.resultSliceSize * (newPage - 1));

    this.resultSlice = this.results.slice(startPos, startPos + this.resultSliceSize);
  }
}
